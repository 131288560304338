import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import DamagedItemImageSource from 'images/damaged-item.jpg';
import DamagedItemThumbImageSource from 'images/featured-image/damaged-item.jpg';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import OptinBlog from 'components/pages/OptinBlog';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, BottomShare, SectionFull, OptinSection} from 'components/blog/SingleBlogStyle';

interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query ImgBlog5Query {
      file(relativePath: { eq: "damaged-item-cover.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="broken mobile phone" />;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="How Do Products Get Damaged | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-how-do-products" })}
        htmlAttributes={{
          lang: 'en',
        }}
         meta={
          [
            {
              name: `description`,
              content: "Damage products can result to product returns. Learn how do products get damaged and how to avoid them.",
            },
            {
              property: `og:title`,
              // content: "How Do Products Get Damaged | Shipkoo",
              content: intl.formatMessage({ id: "blog-post-meta-title-how-do-products" }),
            },
            {
              property: `og:description`,
              content: "Damage products can result to product returns. Learn how do products get damaged and how to avoid them.",
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              property: `og:image`,
              content: DamagedItemImageSource,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="how-do-products-get-damaged"
        title="How Do Products Get Damaged"
        date="2020-03-20"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar />
          <div className="column-half last">
            <p>
              A great eCommerce retailer will always be proud to have top-quality products, caring customer support and a competitive fulfillment that can surely give a great satisfactory rating for your customers. However, there’s a thing that could trip out this smooth flow of your business. Returns are the thing that any kind of retail businesses are afraid to encounter and if not taken care of properly, it can damage the reputation of your online store.
            </p>
            
            <p>
              You must always know the reason why the customer would like to return a product. By doing this, you’ll be able to prevent this situation and lessen the chances of getting returns that you’ll be dealing with. Do you have an idea how products get damaged?
            </p>

            <ul>
              <li>
                <strong>Items Not Packed Properly</strong>
              </li>
            </ul>

            <p>
              Unfortunately, some people would like to lessen their expenses, other shippers use old boxes or not using the right amount of packaging materials to the product. This may save some of your money but there’s a big chance that your products are at risk to be damaged from their journey to your customer.
            </p>

            <ul>
              <li>
                <strong>Mishandled by Your Courier</strong>
              </li>
            </ul>

            <p>
              Many shipping companies mishandle packages. Some companies may seem to have lower prices, but they often charge hidden fees that actually make them more expensive. Inexperienced employees which lack efficiency, lose track of packages, and damage fragile items.
            </p>

            <ul>
              <li>
                <strong>Bad Warehouse Conditions</strong>
              </li>
            </ul>

            <p>
              There are many things that a bad warehouse can be a reason for a damaged product. Things like: room temperature, minimal security leading to theft or a bad warehouse location that is more likely to get hit by natural disasters.
            </p>

             <ul>
              <li>
                <strong>Weather Situations</strong>
              </li>
            </ul>

            <p>
              Inevitable events like natural disasters can be a big reason why your customers are receiving a damaged product whether it is in the warehouse or in it’s delivery period.
            </p>
            
            
            <Image src={DamagedItemImageSource} alt="damage product" />

            <p>
              Damaged products are inevitable in eCommerce, but if you choose the right logistic partner, that won’t give you much of a problem. Shipkoo handles your entire logistics operations, starting from the manufacturer all the way to the customer. We specialize in express shipping and origin fulfillment. Guarantee that all items are in good condition when they arrive to your customers. 
            </p>
            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="Facebook Icon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="Twitter Icon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="Linked Icon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>
          <Clear></Clear>
        </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;

